import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import IconCircle from "../../components/IconCircle";

import bgHero from "../../assets/image/png/subscribe-pattern.png";
import techStack from "../../assets/image/png/techstack-removebg.png";

import application_suffer from "../../assets/image/webp/application_suffer.webp";
import automation from "../../assets/image/webp/automation.webp";
import cloud_expenses from "../../assets/image/webp/cloud_expenses.webp";

const SectionStyled = styled(Section)`
  &::before {
    opacity: 0.085;
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(${bgHero}) top center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        hero
        className="position-relative"
        pt={["50px", null, "75px", "100px"]}
        pb={["100px", null, "150px", "200px"]}
      >
        <Container>
          <Box className="d-flex flex-column align-items-center text-center">
            <IconCircle mb="2.5rem">
              <i className="icon icon-code-2"></i>
            </IconCircle>

            <Title variant="hero" mb="1.5rem">
            DevOps as a Service
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              It has an expert team in building the Cloud infrastructure that your Startup's applications need.
              <br />
              Give your company the best experience possible.
            </Text>
            <Link
              to="works"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              <Button>Schedule a Meeting</Button>
            </Link>
          </Box>
          <Box className="d-flex flex-column align-items-center text-center">
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              <br />

              We work with the main technologies.
              <br />
              <div className="mt-5">
                  <img src={techStack} alt="techstack" className="img-fluid" />
              </div>
            </Text>
          </Box>
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Box className="d-flex flex-column align-items-left text-left">
              <Row>
                <Col lg="4" className="pl-lg-4">
                  <img src={application_suffer} alt="application_suffer" className="img-fluid rounded float-end" />
                </Col>
                <Col lg="6">
                  <Title variant="secSm" className="my-4">
                  Does your application suffer constant falls?.{" "}
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    We help you create an infrastructure capable of having high availability to improve the user experience of your customers.
                    </Text>
                  </Col>
              </Row>
            </Box>
            <Box className="d-flex flex-column align-items-right text-right">
              <Row>
                <Col lg="6">
                  <Title variant="secSm" className="my-4">
                  Do you still have many Manual processes?{" "}
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    We automate the construction and deployment processes of your application (CI/CD), backups, automatic tasks, etc.
                   </Text>
                </Col>
                <Col lg="4" className="pl-lg-4">
                    <img src={automation} alt="automation" className="img-fluid rounded float-end"/>
                </Col>
              </Row>
            </Box>
            <Box className="d-flex flex-column align-items-left text-left">
              <Row>
                <Col lg="4" className="pl-lg-4">
                    <img src={cloud_expenses} alt="cloud_expenses" className="img-fluid rounded float-end"/>
                </Col>
                <Col lg="6">
                  <Title variant="secSm" className="my-4">
                  Do you want to reduce Cloud expenses?{" "}
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    We create savings plans and help you apply for credits that help finance the infrastructure in your Cloud Provider.
                   </Text>
                </Col>
              </Row>
            </Box>
          </Container>
        </Section>
      </SectionStyled>
    </>
  );
};

export default Hero;
